.search-form .row {
  margin-bottom: 10px;
}

input#search_input {
  width: 100%;
}

.searchWrapper {
  border: 1px solid darkturquoise !important;
}

.filled input#search_input::placeholder {
  color: black !important;
}
