.Toastify__toast-container--top-right {
    /*  overflow-y: scroll; */
  /*  overflow-y: scroll; */
    height: 175px;
    position: fixed;
    /* top: 1em; */
    top: 6em;
    right: 1em;
    width: 33%;
  }
  
  .Toastify{
       position: relative;
  }
  
  