/* SlideDrawer.css 
html { 
   height: 100% 
}body { 
   margin: 0; 
   padding: 0; 
   height: 100% ;
}
*/
.side-drawer { 
    /*  height: 100%; */
    background: white;
    position: fixed;
    top: 50%;
    right: 0;
    width: 30%;
    z-index: 300;
    box-shadow: 1px 0px 7px rgb(0 0 0 / 50%);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    padding: 25px;
    background-color: #e9ecef;
    margin-left: -100px;
    margin-top: -100px;
   }
   
   .side-drawer.open {
      transform: translateX(0);
   }