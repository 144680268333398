.table-container {
  padding-bottom: "100px";
  background: "#e9ecef";
}

.pratica {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: rgba(222, 226, 230, 0.33);
  margin-bottom: 7px;
  border-bottom: 1px solid rgb(94, 92, 89);
}

.label-pratica {
  color: rgb(117, 117, 117);
}

.tabella {
  border-color: rgb(197 197 197);
}

.blocco-row {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: rgba(222, 226, 230, 0.33) none repeat scroll 0% 0%;
  margin-bottom: 7px;
  border-bottom: 1px solid rgb(94, 92, 89);
}

@media (min-width: 992px) {
  .hideOnDesktop {
    display: none;
  }
}
