.user{
    padding-bottom: 1rem; 
    padding-top: 1rem; 
    background: rgba(222, 226, 230, 0.33); 
    margin-bottom: 7px; 
    border-bottom: 1px solid rgb(94, 92, 89);
}

.label-user {

    color: rgb(117, 117, 117);
}

.row-user {
    padding-bottom: 10px;
}

.tabella {
    border-color: rgb(197 197 197);
    margin-bottom: 10px;
    font-size: 0.9rem;
}


body > #root > div {
    height: 100%;
    background-color: rgb(234, 234, 236);
  }