.containerfffff{
    display: grid;
    grid-template-rows: 10% 80% 10%;
    gap: 0.1rem;
    overflow: hidden;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-rows: 15% 70% 15%;
    }
  }
    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
  
    }
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
  
    }
   
    .username h3{
  
        color: white;
  
    }
    .chat-messages {
      padding: 1rem 18rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow: auto;
      background: #f1f1f1;
  
    }
      .message{
        display: flex;
        align-items: center;
        width: 100%;
       border: solid 1px white; 
       /*  border-radius: 1rem;*/
      }
      .content {
        
          overflow-wrap: break-word;
          padding: 1rem;
          font-size: 1.1rem;
          border-radius: 1rem;
          color: #f9fafb;
          @media screen and (min-width: 720px) and (max-width: 1080px) {
            max-width: 70%;
          }
        }
  
      .sended{
        justify-content: flex-end;
  
        background-color: #5f65c5;
      
      }
  
      .sended p{
          text-align: end;
        }
  
      .recieved{
        justify-content: flex-start;
  
        background-color: #366463dd;
  
      }
    
      .recieved p{
          text-align: start;
    
        }
  