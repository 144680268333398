#Nav_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
  /*  color: #fff; */
  }

  .Nav_link:link {
    color: rgba(80, 78, 78, 0.75);
  }
  .Nav_link:visited {

   color: rgba(80, 78, 78, 0.75);
  }
  .Nav_link:hover {
    color: rgb(83, 145, 240);
    padding-right: .5rem;
    padding-left: .5rem;
    margin-left: 6px;
  }
  .Nav_link:active {
    color: teal;
  }

  .unselected {
    /*  background-color: yellow; */
    /*  border-bottom: 0.4rem solid teal; */
   /* font-weight: 500; */
    text-decoration: none;
    border-left: solid 0.1rem;
    margin-left: 12px;
    padding-left: 12px;
    }


  .selected {
  text-decoration: underline;
/*  color: #fff !important;; */
border-left: solid 0.1rem;
    margin-left: 12px;
    padding-left: 12px;
  font-weight: 1500;
  cursor: pointer;
  }
