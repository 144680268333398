.nav {
    display:flex;
    flex-wrap:wrap;
    padding-left:0;
    margin-bottom:0;
    list-style:none
   }
   .nav-link {
    display:block;
    padding:.5rem 1rem;
    color:#0d6efd;
    text-decoration:none;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
   }
   @media (prefers-reduced-motion:reduce) {
    .nav-link {
     transition:none
    }
   }
   .nav-link:focus,
   .nav-link:hover {
    color:#0a58ca
   }
   .nav-link.disabled {
    color:#6c757d;
    pointer-events:none;
    cursor:default
   }
   .nav-tabs {
    border-bottom:1px solid #dee2e6
   }
   .nav-tabs .nav-link {
    margin-bottom:-1px;
    background:0 0;
    border:1px solid transparent;
    border-top-left-radius:.25rem;
    border-top-right-radius:.25rem
   }

   .my-nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #ffffff;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
   .nav-tabs .nav-link:focus,
   .nav-tabs .nav-link:hover {
   border-color:#e9ecef #e9ecef transparent; 

    isolation:isolate
   }
   .nav-tabs .nav-link.disabled {
    color:#6c757d;
    background-color:transparent;
    border-color:transparent
   }
   .nav-tabs .nav-item.show .nav-link,
   .nav-tabs .nav-link.active {
    color: #ffffff;
    font-weight: 700;
    background-color: #dc3545;
    border-color:#dee2e6 #dee2e6 #fff 
   }
   .nav-tabs .dropdown-menu {
    margin-top:-1px;
    border-top-left-radius:0;
    border-top-right-radius:0
   }
   .nav-pills .nav-link {
    background:0 0;
    border:0;
    border-radius:.25rem
   }
   .nav-pills .nav-link.active,
   .nav-pills .show>.nav-link {
    color:#fff;
    background-color:#0d6efd
   }
   .nav-fill .nav-item,
   .nav-fill>.nav-link {
    flex:1 1 auto;
    text-align:center
   }
   .nav-justified .nav-item,
   .nav-justified>.nav-link {
    flex-basis:0;
    flex-grow:1;
    text-align:center
   }
   .nav-fill .nav-item .nav-link,
   .nav-justified .nav-item .nav-link {
    width:100%
   }
   .tab-content>.tab-pane {
    display:none
   }
   .tab-content>.active {
    display:block
   }
   .navbar {
    position:relative;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    padding-top:.5rem;
    padding-bottom:.5rem
   }
   .navbar>.container,
   .navbar>.container-fluid,
   .navbar>.container-lg,
   .navbar>.container-md,
   .navbar>.container-sm,
   .navbar>.container-xl,
   .navbar>.container-xxl {
    display:flex;
    flex-wrap:inherit;
    align-items:center;
    justify-content:space-between
   }
   .navbar-brand {
    padding-top:.3125rem;
    padding-bottom:.3125rem;
    margin-right:1rem;
    font-size:1.25rem;
    text-decoration:none;
    white-space:nowrap
   }
   .navbar-nav {
    display:flex;
    flex-direction:column;
    padding-left:0;
    margin-bottom:0;
    list-style:none
   }
   .navbar-nav .nav-link {
    padding-right:0;
    padding-left:0
   }
   .navbar-nav .dropdown-menu {
    position:static
   }
   .navbar-text {
    padding-top:.5rem;
    padding-bottom:.5rem
   }
   .navbar-collapse {
    flex-basis:100%;
    flex-grow:1;
    align-items:center
   }
   .navbar-toggler {
    padding:.25rem .75rem;
    font-size:1.25rem;
    line-height:1;
    background-color:transparent;
    border:1px solid transparent;
    border-radius:.25rem;
    transition:box-shadow .15s ease-in-out
   }
   @media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
     transition:none
    }
   }
   .navbar-toggler:hover {
    text-decoration:none
   }
   .navbar-toggler:focus {
    text-decoration:none;
    outline:0;
    box-shadow:0 0 0 .25rem
   }
   .navbar-toggler-icon {
    display:inline-block;
    width:1.5em;
    height:1.5em;
    vertical-align:middle;
    background-repeat:no-repeat;
    background-position:50%;
    background-size:100%
   }
   .navbar-nav-scroll {
    max-height:var(--bs-scroll-height,75vh);
    overflow-y:auto
   }
   @media (min-width:576px) {
    .navbar-expand-sm {
     flex-wrap:nowrap;
     justify-content:flex-start
    }
    .navbar-expand-sm .navbar-nav {
     flex-direction:row
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
     position:absolute
    }
    .navbar-expand-sm .navbar-nav .nav-link {
     padding-right:.5rem;
     padding-left:.5rem
    }
    .navbar-expand-sm .navbar-nav-scroll {
     overflow:visible
    }
    .navbar-expand-sm .navbar-collapse {
     display:flex!important;
     flex-basis:auto
    }
    .navbar-expand-sm .navbar-toggler,
    .navbar-expand-sm .offcanvas-header {
     display:none
    }
    .navbar-expand-sm .offcanvas {
     position:inherit;
     bottom:0;
     z-index:1000;
     flex-grow:1;
     visibility:visible!important;
     background-color:transparent;
     border-right:0;
     border-left:0;
     transition:none;
     transform:none
    }
    .navbar-expand-sm .offcanvas-bottom,
    .navbar-expand-sm .offcanvas-top {
     height:auto;
     border-top:0;
     border-bottom:0
    }
    .navbar-expand-sm .offcanvas-body {
     display:flex;
     flex-grow:0;
     padding:0;
     overflow-y:visible
    }
   }
   @media (min-width:768px) {
    .navbar-expand-md {
     flex-wrap:nowrap;
     justify-content:flex-start
    }
    .navbar-expand-md .navbar-nav {
     flex-direction:row
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
     position:absolute
    }
    .navbar-expand-md .navbar-nav .nav-link {
     padding-right:.5rem;
     padding-left:.5rem
    }
    .navbar-expand-md .navbar-nav-scroll {
     overflow:visible
    }
    .navbar-expand-md .navbar-collapse {
     display:flex!important;
     flex-basis:auto
    }
    .navbar-expand-md .navbar-toggler,
    .navbar-expand-md .offcanvas-header {
     display:none
    }
    .navbar-expand-md .offcanvas {
     position:inherit;
     bottom:0;
     z-index:1000;
     flex-grow:1;
     visibility:visible!important;
     background-color:transparent;
     border-right:0;
     border-left:0;
     transition:none;
     transform:none
    }
    .navbar-expand-md .offcanvas-bottom,
    .navbar-expand-md .offcanvas-top {
     height:auto;
     border-top:0;
     border-bottom:0
    }
    .navbar-expand-md .offcanvas-body {
     display:flex;
     flex-grow:0;
     padding:0;
     overflow-y:visible
    }
   }
   @media (min-width:992px) {
    .navbar-expand-lg {
     flex-wrap:nowrap;
     justify-content:flex-start
    }
    .navbar-expand-lg .navbar-nav {
     flex-direction:row
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
     position:absolute
    }
    .navbar-expand-lg .navbar-nav .nav-link {
     padding-right:.5rem;
     padding-left:.5rem
    }
    .navbar-expand-lg .navbar-nav-scroll {
     overflow:visible
    }
    .navbar-expand-lg .navbar-collapse {
     display:flex!important;
     flex-basis:auto
    }
    .navbar-expand-lg .navbar-toggler,
    .navbar-expand-lg .offcanvas-header {
     display:none
    }
    .navbar-expand-lg .offcanvas {
     position:inherit;
     bottom:0;
     z-index:1000;
     flex-grow:1;
     visibility:visible!important;
     background-color:transparent;
     border-right:0;
     border-left:0;
     transition:none;
     transform:none
    }
    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
     height:auto;
     border-top:0;
     border-bottom:0
    }
    .navbar-expand-lg .offcanvas-body {
     display:flex;
     flex-grow:0;
     padding:0;
     overflow-y:visible
    }
   }
   @media (min-width:1200px) {
    .navbar-expand-xl {
     flex-wrap:nowrap;
     justify-content:flex-start
    }
    .navbar-expand-xl .navbar-nav {
     flex-direction:row
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
     position:absolute
    }
    .navbar-expand-xl .navbar-nav .nav-link {
     padding-right:.5rem;
     padding-left:.5rem
    }
    .navbar-expand-xl .navbar-nav-scroll {
     overflow:visible
    }
    .navbar-expand-xl .navbar-collapse {
     display:flex!important;
     flex-basis:auto
    }
    .navbar-expand-xl .navbar-toggler,
    .navbar-expand-xl .offcanvas-header {
     display:none
    }
    .navbar-expand-xl .offcanvas {
     position:inherit;
     bottom:0;
     z-index:1000;
     flex-grow:1;
     visibility:visible!important;
     background-color:transparent;
     border-right:0;
     border-left:0;
     transition:none;
     transform:none
    }
    .navbar-expand-xl .offcanvas-bottom,
    .navbar-expand-xl .offcanvas-top {
     height:auto;
     border-top:0;
     border-bottom:0
    }
    .navbar-expand-xl .offcanvas-body {
     display:flex;
     flex-grow:0;
     padding:0;
     overflow-y:visible
    }
   }
   @media (min-width:1400px) {
    .navbar-expand-xxl {
     flex-wrap:nowrap;
     justify-content:flex-start
    }
    .navbar-expand-xxl .navbar-nav {
     flex-direction:row
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
     position:absolute
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
     padding-right:.5rem;
     padding-left:.5rem
    }
    .navbar-expand-xxl .navbar-nav-scroll {
     overflow:visible
    }
    .navbar-expand-xxl .navbar-collapse {
     display:flex!important;
     flex-basis:auto
    }
    .navbar-expand-xxl .navbar-toggler,
    .navbar-expand-xxl .offcanvas-header {
     display:none
    }
    .navbar-expand-xxl .offcanvas {
     position:inherit;
     bottom:0;
     z-index:1000;
     flex-grow:1;
     visibility:visible!important;
     background-color:transparent;
     border-right:0;
     border-left:0;
     transition:none;
     transform:none
    }
    .navbar-expand-xxl .offcanvas-bottom,
    .navbar-expand-xxl .offcanvas-top {
     height:auto;
     border-top:0;
     border-bottom:0
    }
    .navbar-expand-xxl .offcanvas-body {
     display:flex;
     flex-grow:0;
     padding:0;
     overflow-y:visible
    }
   }
   .navbar-expand {
    flex-wrap:nowrap;
    justify-content:flex-start
   }
   .navbar-expand .navbar-nav {
    flex-direction:row
   }
   .navbar-expand .navbar-nav .dropdown-menu {
    position:absolute
   }
   .navbar-expand .navbar-nav .nav-link {
    padding-right:.5rem;
    padding-left:.5rem
   }
   .navbar-expand .navbar-nav-scroll {
    overflow:visible
   }
   .navbar-expand .navbar-collapse {
    display:flex!important;
    flex-basis:auto
   }
   .navbar-expand .navbar-toggler,
   .navbar-expand .offcanvas-header {
    display:none
   }
   .navbar-expand .offcanvas {
    position:inherit;
    bottom:0;
    z-index:1000;
    flex-grow:1;
    visibility:visible!important;
    background-color:transparent;
    border-right:0;
    border-left:0;
    transition:none;
    transform:none
   }
   .navbar-expand .offcanvas-bottom,
   .navbar-expand .offcanvas-top {
    height:auto;
    border-top:0;
    border-bottom:0
   }
   .navbar-expand .offcanvas-body {
    display:flex;
    flex-grow:0;
    padding:0;
    overflow-y:visible
   }
   .navbar-light .navbar-brand,
   .navbar-light .navbar-brand:focus,
   .navbar-light .navbar-brand:hover {
    color:rgba(0,0,0,.9)
   }
   .navbar-light .navbar-nav .nav-link {
    color:rgba(0,0,0,.55)
   }
   .navbar-light .navbar-nav .nav-link:focus,
   .navbar-light .navbar-nav .nav-link:hover {
    color:rgba(0,0,0,.7)
   }
   .navbar-light .navbar-nav .nav-link.disabled {
    color:rgba(0,0,0,.3)
   }
   .navbar-light .navbar-nav .nav-link.active,
   .navbar-light .navbar-nav .show>.nav-link {
    color:rgba(0,0,0,.9)
   }
   .navbar-light .navbar-toggler {
    color:rgba(0,0,0,.55);
    border-color:rgba(0,0,0,.1)
   }
   .navbar-light .navbar-toggler-icon {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
   }
   .navbar-light .navbar-text {
    color:rgba(0,0,0,.55)
   }
   .navbar-light .navbar-text a,
   .navbar-light .navbar-text a:focus,
   .navbar-light .navbar-text a:hover {
    color:rgba(0,0,0,.9)
   }
   .navbar-dark .navbar-brand,
   .navbar-dark .navbar-brand:focus,
   .navbar-dark .navbar-brand:hover {
    color:#fff
   }
   .navbar-dark .navbar-nav .nav-link {
    color:hsla(0,0%,100%,.55)
   }
   .navbar-dark .navbar-nav .nav-link:focus,
   .navbar-dark .navbar-nav .nav-link:hover {
    color:hsla(0,0%,100%,.75)
   }
   .navbar-dark .navbar-nav .nav-link.disabled {
    color:hsla(0,0%,100%,.25)
   }
   .navbar-dark .navbar-nav .nav-link.active,
   .navbar-dark .navbar-nav .show>.nav-link {
    color:#fff
   }
   .navbar-dark .navbar-toggler {
    color:hsla(0,0%,100%,.55);
    border-color:hsla(0,0%,100%,.1)
   }
   .navbar-dark .navbar-toggler-icon {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
   }
   .navbar-dark .navbar-text {
    color:hsla(0,0%,100%,.55)
   }
   .navbar-dark .navbar-text a,
   .navbar-dark .navbar-text a:focus,
   .navbar-dark .navbar-text a:hover {
    color:#fff
   }
  