/*
#Nav_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .Nav_link:link {
    color: rgba(80, 78, 78, 0.75);
  }
  .Nav_link:visited {
  color: #fff; 

  }
  .Nav_link:hover {
    color: rgb(83, 145, 240);
    padding-right: .5rem;
    padding-left: .5rem;
    margin-left: 6px;
  }
  .Nav_link:active {
    color: teal;
  }
*/
.unselectedLink {
    color: #fff !important;
    cursor: pointer;
     /* padding: 4px; */
    font-weight: 1500;
    font-size: large;
    padding-bottom: 1rem;
    margin: 0px 5px 0px 21px;
    }


  .selectedLink {
    color: #fff !important;
    cursor: pointer;
    padding-bottom: 1rem;
    font-weight: 1500;
    font-size: large;
    border-bottom: solid 1px white;
    margin: 0px 5px 0px 21px;
  }


  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #f1c40f;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}