.pointer {cursor: pointer;}

.pointer-events-none {
  pointer-events: none;
}

.wrapper {
  cursor: not-allowed;
}

