.header-consulente {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.button-container {
  margin-right: 300px;
}

@media (max-width: 1200px) {
  .button-container {
    margin-right: 100px;
  }
}

@media (max-width: 1000px) {
  .button-container {
    margin-right: 50px;
  }
}

@media (max-width: 992px) {
  .button-container {
    display: none;
  }
}
