

#root{
    height: 100vh;
    background-color: #0c4a86;
}


/* unvisited link */
#policy>a:link {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  
  /* visited link */
  #policy>a:visited {
    color: rgb(168, 171, 168);
    text-decoration: none;
  }
  
  /* mouse over link */
  #policy>a:hover {
    color: rgb(6, 183, 248);
    text-decoration: none;
  }
  
  /* selected link */
  #policy> a:active {
    color: rgb(251, 251, 253);
    text-decoration: none;
  }